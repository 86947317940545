<template>
  <div
    class="accordion"
    role="tablist"
  >
    <h2 class="faq-heading-title my-2 d-flex justify-content-center">
      سوالات متداول
    </h2>
    <div
      v-for="question in questions"
      :key="question.id+'faqitems'"
    >
      <b-card
        no-body
        class="mb-1"
      >
        <b-card-header
          block
          header-tag="header"
          class="p-0"
          role="tab"
          :class="question.show?'not-collapsed':''"
        >
          <a
            v-b-toggle="`accordion-${question.id}`"
            class="p-2 w-100"
            @click="question.show = !question.show"
          >
            <span class="faq-title">
              {{ question.question }}
            </span>
            <span>
              <feather-icon
                :icon="question.show ?'ChevronUpIcon':'ChevronDownIcon'"
                size="18"
              />
            </span>
          </a>
        </b-card-header>
        <b-collapse
          :id="`accordion-${question.id}`"
          :v-model="question.show"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>{{ question.answer }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BCardText, BCardBody, BCardHeader, BCollapse, VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BCollapse,

  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          show: false,
          question: `lore  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck qui?`,
          answer: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        },
        {
          id: 2,
          show: false,
          question: `lore  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck qui?`,
          answer: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        },
        {
          id: 3,
          show: false,
          question: `lore  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck qui?`,
          answer: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        },
        {
          id: 4,
          show: false,
          question: `lore  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck qui?`,
          answer: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        },
      ],
    }
  },

}
</script>
<style scoped>
.faq-heading-title{
    font-size: 28px;
    font-weight: 900;
}
a.not-collapsed{
    background-color: #002352 !important;
    color: white !important ;
}
.faq-title{
    font-size: 16px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
